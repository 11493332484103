<template>
  <div id="Lucky">
    <div class="Lucky_nav">
      <div
        class="Lucky_nav_item"
        v-for="(item, index) in TypeList"
        :key="index"
        :class="{ active: index == TypeIdx }"
        @click="ChangeType(index, item.id)"
      >
        <i></i>
        <img :src="item.cover" alt="" />
      </div>
    </div>
    <div class="Lucky_Serch">
      <input type="text" v-model="SerchData" />
      <van-button type="info" size="small" @click="GetSerch"
        >搜索装备</van-button
      >
    </div>
    <!--		:style="`background-image:url(${require('@/assets/images/Lucky/LV' + item.lv + '.png')})`"-->
    <div class="Lucky_list">
      <div
        class="Lucky_item"
        v-for="(item, index) in EquipmentList"
        :key="index"
        @click="ToOpen(item,item.id)"
      >
        <div class="Lucky_item_top">
          <p>
            <img src="@/assets/images/public/Gold.png" alt="" />{{ item.bean }}
          </p>
          <span>{{ item.dura_alias }}</span>
        </div>
        <div
          class="Lucky_item_pic"
          :style="{ 'background-image': 'url(' + item.lv_bg_image + ')' }"
        >
          <img :src="item.cover" alt="" />
        </div>
        <div class="Lucky_item_data">
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LuckyType, LuckyList, LuckySo } from "@/network/api.js";
import bus from './bus'
export default {
  name: "Lucky",
  data() {
    return {
      TypeList: [], //类别列表
      EquipmentList: [], //装备列表
      TypeIdx: 0, //类别样式索引
      TypeId: 1, //类别id
      SerchData: "", //搜索关键字
    };
  },
  created() {
    this.GetLuckyType();
    this.GetLuckyList();
  },
  methods: {
    //装备Type列表
    GetLuckyType() {
      LuckyType().then((res) => {
        // console.log(res.data.data)
        this.TypeList = res.data.data;
      });
    },
    // 改变Type
    ChangeType(index, id) {
      this.TypeIdx = index;
      this.TypeId = id;
      this.GetLuckyList();
    },

    //装备搜索
    GetSerch() {
      LuckySo(this.SerchData).then((res) => {
        // console.log(res)
        this.EquipmentList = res.data.data;
      });
    },

    //装备列表
    GetLuckyList() {
      LuckyList(this.TypeId).then((res) => {
        // console.log(res.data.data)
        this.EquipmentList = res.data.data;
      });
    },

    //路由跳转开箱
    ToOpen(item,id) {
      this.$bus.$emit("detile");
      // this.$router.push({
      //   path: "/Lucky_open",
      //   query: {
      //     id: id,
      //   },
      // });
      this.$emit('onEmitIndex', item)
				 this.$emit('idBox');
				// window.eventBus.$emit('LuckyBox')
    },
  },
};
</script>

<style lang="scss">
#Lucky {
  padding: 10px;
  box-sizing: border-box;
  .Lucky_nav {
    width: 100%;
    height: 95px;
    background: rgba(18, 28, 39, 0.8);
    border-radius: 4px;
    overflow-x: auto;
    white-space: nowrap;
    position: sticky;
    top: 0;
    .Lucky_nav_item {
      display: inline-block;
      width: 70px;
      height: 70px;
      margin: 5px;
      padding: 5px;
      position: relative;
      line-height: 70px;
      text-align: center;
      img {
        max-width: 90%;
        max-height: 90%;
        vertical-align: middle;
        filter: grayscale(1);
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        transform: translateY(-50%);
        width: 0.02rem;
        content: "";
        height: 0.02rem;
        border-radius: 50%;
        background: none;
        box-shadow: none;
      }
      &.active {
        background-image: url("../../assets/images/topNav.png");
        background-size: 100% 100%;
        img {
          filter: none;
        }
        i {
          // background: rgba(67,42,192,.6);
          /*box-shadow: 0 0 35px 20px #ffd300;*/
        }
      }
    }
  }

  .Lucky_Serch {
    width: 100%;
    display: flex;
    margin-top: 10px;
    input {
      flex: 1;
      border-radius: 4px 0 0 4px;
      border: 1px solid #ffd322;
      background: rgba($color: #ffd322, $alpha: 0.2);
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  .Lucky_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .Lucky_item {
      width: 48.6%;
      border: 1px solid rgba(18, 28, 39, 0.8);
      padding: 5px 10px 12px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 10px;
      background-size: 100% 100%;
      background-color: #131426;
      .Lucky_item_top {
        width: 100%;
        display: flex;
        height: 30px;

        p {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #75cd66;

          img {
            width: 18px;
          }
        }

        span {
          display: block;
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          color: #fff;
        }
      }

      .Lucky_item_pic {
        width: 80%;
        height: 100px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: 100% !important;
        background-position: center !important;
        background-repeat: no-repeat !important;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .Lucky_item_data {
        width: 100%;
        color: #fff;
        text-align: center;

        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 3px;
        }
      }
    }
  }
}
</style>
